@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


@font-face {
    font-family: 'AvenirLTStdLight';
    src: url('../fonts/AvenirLTStdLight.eot');
    src: url('../fonts/AvenirLTStdLight.eot') format('embedded-opentype'),
         url('../fonts/AvenirLTStdLight.woff2') format('woff2'),
         url('../fonts/AvenirLTStdLight.woff') format('woff'),
         url('../fonts/AvenirLTStdLight.ttf') format('truetype'),
         url('../fonts/AvenirLTStdLight.svg#AvenirLTStdLight') format('svg');
}

@font-face {
    font-family: 'AvenirLTStdBook';
    src: url('../fonts/AvenirLTStdBook.eot');
    src: url('../fonts/AvenirLTStdBook.eot') format('embedded-opentype'),
         url('../fonts/AvenirLTStdBook.woff2') format('woff2'),
         url('../fonts/AvenirLTStdBook.woff') format('woff'),
         url('../fonts/AvenirLTStdBook.ttf') format('truetype'),
         url('../fonts/AvenirLTStdBook.svg#AvenirLTStdBook') format('svg');
}


body{
    font-size: 16px;
    overflow-x: hidden !important;
    font-family: 'Nunito', sans-serif !important;
    scroll-behavior: smooth;
}
.no-text-decoration{
    text-decoration: none !important;
}
.full-width{
    float: left;
    width: 100%;
}
.text-green{
    color: #00749C !important;
}
.text-light-green{
    color: #57C6D6 !important;
}
.text-red{
    color: #ED1752 !important;
}
.font-300{
    font-weight: 300 !important;
}
.font-normal{
    font-weight: 400 !important;
}
.font-600{
    font-weight: 600 !important;
}
.font-500{
    font-weight: 500 !important;
}
.font-700{
    font-weight: 700 !important;
}
.font-800{
    font-weight: 800 !important;
}
.text-right {
	text-align: right !important;
}
.float-right {
	float: right !important;
}
.float-left {
	float: left !important;
}
.text-left {
	text-align: left !important;
}
.text-right {
	text-align: right !important;
}
.mr-1, .mx-1 {
	margin-right: .25rem !important;
}
.mr-2, .mx-2 {
	margin-right: .5rem !important;
}
.mr-3, .mx-3 {
    margin-right: 1rem !important;
}
.mr-4, .mx-4 {
    margin-right: 1.5rem !important;
}
.mr-5, .mx-5 {
    margin-right: 3rem !important;
}
.ml-1, .mx-1 {
	margin-left: .25rem !important;
}
.ml-2, .mx-2 {
	margin-left: .5rem !important;
}
.ml-3, .mx-3 {
    margin-left: 1rem !important;
}
.ml-4, .mx-4 {
    margin-left: 1.5rem !important;
}
.ml-5, .mx-5 {
    margin-left: 3rem !important;
}
.rounded-100{
    border-radius: 50px;
}
.rounded-10{
    border-radius: 10px;
}
.border-green{
    border-color: #00749C !important;
}
.ant-btn.btn-blue {
	color: #fff;
	background-color: #57C6D6;
	border-color: #57C6D6;
    box-shadow: none;
    min-width: 160px;
    font-weight: 700;
}
.ant-btn.btn-white {
	color: #57C6D6;
	background-color: #fff;
	border-color: #fff;
    box-shadow: none;
    min-width: 160px;
    font-weight: 700;
}
.ant-btn.btn-white:hover{
    color: #fff;
    background-color: #57C6D6;
    border-color: #57C6D6;
}
.ant-btn.btn-white a{
    text-decoration: none;
}
.btn-muted,
.btn-muted:hover{
    color: #fff !important;
	background-color: #999 !important;
	border-color: #999 !important;
    box-shadow: none;
}
.ant-btn.btn-outline-light-green {
	color: #57C6D6;
	background-color: #fff;
	border-color: #57C6D6;
    border-width: 2px;
    height: auto;
    box-shadow: none;
}
.btn-100{
    min-width: 100px;
}
.btn-150{
    min-width: 150px;
}
.ant-btn.btn-outline-light-green:hover {
	color: #fff;
	background-color: #57C6D6;
	border-color: #57C6D6;
}
.ant-btn.btn-blue a{
    text-decoration: none !important;
}
.ant-btn.btn-blue:hover {
	color: #fff;
	background-color: #1D3B51;
	border-color: #1D3B51;
}
.ant-btn.btn-dark-green {
	color: #fff;
    font-weight: 700;
    border-radius: 8px;
    min-width: 160px;
	background-color: #01749C;
	border-color: #01749C;
    box-shadow: none;
}
.ant-btn.btn-red {
	color: #fff;
    font-weight: 700;
    min-width: 160px;
    border-radius: 50px;
	background-color: #ED1752;
	border-color: #ED1752;
    box-shadow: none;
}
.ant-btn.btn-red:hover{
    background-color: #e00e4a;
	border-color: #e00e4a;
}
.ant-btn.rolover-white:hover{
    color: #e00e4a;
    background-color: #fff;
}
.ant-btn.btn-red-outline {
	color: #e00e4a;
    font-weight: 700;
    border-radius: 50px;
    border-width: 2px;
	background-color: transparent;
	border-color: #ED1752;
    box-shadow: none;
    min-width: 160px;
}
.border-radius-10{
    border-radius: 10px !important;
}
.ant-btn.font-800{
    font-size: 19px;
}
.ant-btn.btn-red-outline:hover{
    color: #fff;
	border-color: #e00e4a;
    background-color: #e00e4a;
}
.ant-btn.btn-dark-green a{
    font-weight: 700;
    text-decoration: none !important;
}
.ant-btn.btn-dark-green:hover {
	color: #fff;
	background-color: #1D3B51;
	border-color: #1D3B51;
}
.forgot-pass{
    font-size: 18px;
}
.ant-typography.section-title{
    font-size: 45px;
    font-weight: 700;
}
.wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100%;
    height:100%;
}
.ant-layout{
    background: #fff;
}
.ant-layout-header.header{
    height: auto;
    padding: 20px 0;
    line-height: normal;
    width: 100%;
    z-index: 99;
    position: relative;
    background-color: #00749C;
}
.header .ant-menu{
    /*justify-content: flex-end;*/
    border-bottom: none;
    background-color: transparent;
}

.header .ant-menu.right-menu{
    justify-content: flex-end;
    border-bottom: none;
    background-color: transparent;
}

.header-right .btn.btn-custom img{
    margin: 0 5px 0 0 !important;
}
.btn.btn-custom {
	text-align: center;
	/*padding: 12px 10px;*/
	min-width: 180px;
    font-weight: 700;
    font-size: 15px;
    width: 100%;
    border-radius: 50px;
	display: inline-block;
    text-decoration: none;
}
.btn.btn-custom.btn-white,
.btn.btn-custom.btn-white:hover{
    color: #ED1752;
    background-color: #fff;
}
.btn.btn-custom.btn-green-white,
.btn.btn-custom.btn-green-white:hover{
    color: #fff;
    background-color: #57C5D5;
}
.header-right{
    text-align: right;
}
.header .ant-menu-horizontal > .ant-menu-item::after{
    display: none;
    bottom: -10px;
}

.header .menu-item,
.header .menu-item.a,
.header .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.header .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item a{
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    text-decoration: none;
}
.header .menu-item.active,
.header .menu-item.a.active,
.header .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item a.active,
.header .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item.active a{
    font-weight: 600;
}
.header a.menu-item:hover,
.header .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item a:hover{
    color: #000 !important;
}
.header .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.header .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected a,
.header .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.header .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover a{
    color: #fff;
    text-decoration: none;
}
.header .menu-item.a,
.header .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item a{
    text-decoration: none !important;
}
.header .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.header .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after{
    border-bottom-color: #fff !important;
}
.header .ant-dropdown-trigger > .anticon.anticon-down, .ant-dropdown-link > .anticon.anticon-down{
    position: relative;
    top: -1px;
    font-weight: 700;
    font-size: 18px;
}
.find-vets .ant-dropdown-menu{
    width: 260px;
    text-align: center;
    padding: 10px 0;
    border-radius: 10px;
    overflow: hidden;
    font-size: 16px;
}
.find-vets .ant-dropdown-menu .ant-dropdown-menu-item {
    margin: 15px 0;
    padding: 0;
}
.find-vets .ant-dropdown-menu .ant-dropdown-menu-title-content .icon-file{
    width: 30px;
    position: relative;
    top: 4px;
}
.find-vets .ant-dropdown-menu .ant-dropdown-menu-title-content .icon-search{
    margin: 0 8px 0 0;
    width: 25px;
}
.find-vets .ant-dropdown-menu-item:hover,
.find-vets .ant-dropdown-menu-submenu-title:hover {
	background-color: transparent;
}
.find-vets .ant-dropdown-menu-item,
.find-vets .ant-dropdown-menu-submenu-title {
	font-size: 20px;
}
.find-vets .ant-dropdown-arrow{
    border-width: 10px !important;
    top: 1px;
}
.banner{
    position: relative;
    
}
.banner.default{
    position: relative;
    padding: 20px 0;
    height: 100px;
    background-color: #01749C;
}
.banner-caption{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    padding: 150px 0;
    width: 100%;
    height: 100%;
}

@media (min-width: 1200px) {
    .doghome .banner-caption {
        padding: 240px 0;
    }

    .cathome .banner-caption {
        padding: 300px 0;
    }
}

.about-us-banner-caption .tagline,
.banner-caption .tagline{
    font-size: 53px;
    font-weight: 700;
    line-height: 70px;
}
.about-us-banner-caption .tagline-sub,
.banner-caption .tagline-sub{
    font-weight: 700;
    line-height: 70px;
}
.about-us-banner-caption .sub-tagline
.banner-caption .sub-tagline{
    font-size: 44px;
    font-weight: 400;
}
.banner .form-get-started{
    position: absolute;
    bottom: -20px;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 99;
}
.form-get-started{
    padding: 0 15px;
}
.form-get-started .ant-form-item-explain{
    position: absolute;
    bottom: -25px;
    left: 0;
}
.form-get-started .ant-form-inline .ant-form-item-with-help {
	margin-bottom: 0;
}
.form-pet-search.ant-form-inline {
	position: relative;
	box-shadow: 0px 5px 50px 4px #000;
	border-radius: 10px;
    background-color: #01749C;
}
.form-pet-search.ant-form-inline .ant-form-item{
    margin-right: 0;
    width: 70%;
}
.form-pet-search.ant-form-inline .ant-input-lg{
    height: 40px;
    border-radius: 0.25rem 0 0 0.25rem;
}
.form-pet-search.ant-form-inline .ant-input{
    height: 54px;
    box-shadow: none;
    border: transparent !important;
    border-radius: 9px 0 0 9px;
}
.form-pet-search.ant-form-inline .ant-form-item.btn-search{
    width: 30%;
}
.form-pet-search.ant-form-inline .ant-form-item.btn-search .ant-btn{
    border-radius: 0 10px 10px 0;
    height: 54px;
}
.form-get-started .ant-input-affix-wrapper{
    border-radius: 10px 0 0 10px;
}
.form-pet-search.no-shadow.ant-form-inline{
    box-shadow: none;
}
.icon-fomr-gs{
    width: 32px;
}
.main {
	flex: 1;
	position: relative;
}
.insurance-points{
    padding: 80px 0;
    background: rgba(87, 197, 213, 0.3);
}
.pet-insurance{
    padding: 60px 0;
}
.pet-insurance-box img{
    margin-bottom: 30px;
}
.pet-insurance-box div.ant-typography{
    color: #858F91;
    font-size: 18px;
}
.author-section{
    position: relative;
    float: left;
    width: 100%;
}
.author-section .container{
    height: 100%;
}
.author-section .banner-caption{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 60px 0;
}
.author-section .ant-image{
    float: left;
    width: 100%;
}
.author-section .qoute-block{
    position: absolute;
    bottom: 0;
    right: 0;
    background: #fff;
    border-radius: 10px;
    width: 100%;
    padding: 20px 40px 10px 40px;
}
.author-section .qoute-block::before,
.author-section .qoute-block::after{
    content: "";
    position: absolute;
    top: 10px;
    left: 10px;
    width: 20px;
    height: 20px;
    background: url("../images/icon-block-qoutes.png") no-repeat;
}
.author-section .qoute-block::after{
    top: auto;
    bottom: 10px;
    left: auto;
    right: 10px;
    transform: scaleX(-1);
}
.insurance-plans{
    float: left;
    width: 100%;
    padding: 80px 0;
    background-color: #F5F6FA;
}
.ant-card.plan-box{
    border-radius: 8px;
    border-color: #D6DBDF;
    position: relative;
    border: transparent;
    margin-top: 60px;
    /*filter: drop-shadow(5px 5px 30px rgba(0, 0, 0, 0.1));*/
    box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.1);
}
.ant-card.plan-box .ant-card-body{
    border: none;
    position: relative;
}

.ant-card.plan-box .btn-category,
.recomended-plan .btn-category{
    min-width: 150px;
    position: absolute;
    top: -20px;
    left: 50%;
    height: auto;
    padding: 10px;
    font-weight: 700;
    border: transparent;
    transform: translateX(-50%);
}
.recomended-plan .btn-category {
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
}
.recomended-plan .price-amount{
    font-size: 40px;
    line-height: 35px;
}
.recomended-plan .plan-price {
	padding-left: 180px;
	color: #00749C;
	line-height: normal;
}
.ant-card.plan-box .plan-price {
    color: #57C5D5;
	font-size: 16px;
	font-weight: 700;
}
.ant-card.plan-box .plan-price sub,
.recomended-plan .plan-price sub{
    font-size: 16px;
}
.ant-card.plan-box .plan-price .price-amount{
    font-size: 33px;
    position: relative;
    top: 4px;
}
.home-section .ant-card.plan-box .plan-price .price-amount{
    font-size: 50px;
}
.ant-card.plan-box .from-tag{
    color: #A3A9AC;
}
.ant-card.plan-box .plan-tagline {
	font-size: 22px;
	color: #00749C;
	line-height: normal;
	font-size: 18px;
	position: relative;
	font-weight: 600;
    text-align: center;
}
.home-section .ant-card.plan-box{
    transition: all 0.5s ease-in-out 0s;
}
.home-section .ant-card.plan-box:hover{
    transform: scaleY(1.1);
}
.home-section .ant-card.plan-box .plan-tagline,
.home-section .ant-card.plan-box .what-we-cover,
.home-section .ant-card.plan-box .plan-price .price-amount{
    transition: all 0.5s ease-in-out 0s;
}
.home-section .ant-card.plan-box.selected .plan-tagline,
.home-section .ant-card.plan-box:hover .plan-tagline{
    transform: scale(1.3);
}
.home-section .ant-card.plan-box.selected .plan-price .price-amount,
.home-section .ant-card.plan-box:hover .plan-price .price-amount{
    transform: scale(1.5);
}
.home-section .ant-card.plan-box .what-we-cover{
    visibility: hidden;
    transition: all 0.5s ease-in-out 0s;
}
.home-section .ant-card.plan-box:hover .what-we-cover{
    visibility: visible;
}
.get-quote figure{
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    margin: 0;
    font-size: 20px;
    color: #fff;
    border-radius: 100%;
    background-color: #CDCED0;
}
.get-quote a{
    color: #ED1752 !important;
    text-decoration: underline;
}
.get-quote a:hover{
    color: #b30738 !important;
}
.ant-card.plan-box:hover,
.ant-card.plan-box.selected{
    background-color: #57C6D6;
    border-color: #57C6D6;
}
.ant-card.plan-box{
    transition: all 0.5s ease-in-out 0s;
}
.ant-card.plan-box:hover{
    transform: scaleY(1.1);
}
.ant-card.plan-box.selected:hover{
    transform: scaleY(1);
}
.ant-card.plan-box.selected .btn-blue,
.ant-card.plan-box:hover .btn-blue{
    color: #57C6D6;
    font-size: 18px;
    min-width: 200px;
    top: -24px;
    background-color: #fff;
}
.ant-card.plan-box.selected .btn-blue:hover{
    background-color: #fff;
}
.ant-card.plan-box.selected .from-tag{
    color: #fff;
}
.ant-card.plan-box:hover .plan-price,
.ant-card.plan-box.selected .plan-price,
.ant-card.plan-box.selected .illness-type,
.ant-card.plan-box.selected .what-we-cover .text-light-green,
.ant-card.plan-box:hover .what-we-cover .text-light-green{
    color: #fff !important;
}
.ant-card.plan-box .get-quote{
    display: none;
}
.ant-card.plan-box.selected .get-quote{   
    display: flex;
    justify-content: center;
}
.ant-card.plan-box.selected .get-quote figure{
    color: #57C6D6;
    background-color: #fff;
}
.ant-card.plan-box.selected .plan-tagline::after{
    background-color: #fff;
}
.ant-card.plan-box .ant-image {
	position: absolute;
	top: -100px;
	right: -33px;
	width: 240px;
}
.customise-form .ant-radio-group{
    width: 200px;
    border-radius: 50px;
    border: 1px solid #d9d9d9;
    background-color: #fff;
}
.customise-form .ant-radio-group .ant-radio-button-wrapper{
	border-radius: 50px;
}

.customise-form .ant-radio-group-large .ant-radio-button-wrapper{
    width: 50%;
    border: transparent;
}
.customise-form .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
	color: #fff;
	background: #57C6D6;
	border-color: #57C6D6;
}
.customise-form .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus{
    box-shadow: none;
}
.customise-form .ant-radio-button-wrapper:hover,
.customise-form .ant-radio-button-wrapper {
	color: #57C6D6;
}
.customise-form .ant-radio-button-wrapper:not(:first-child)::before{
    display: none;
}
.customise-form .ant-input,
.customise-form .ant-upload .ant-btn-lg,
.customise-form .ant-picker.ant-picker-large,
.customise-form .ant-select:not(.ant-select-customize-input) .ant-select-selector,
.customise-form .ant-input-affix-wrapper{
    border-radius: 8px;
    color: #7f7d7d;
}
.customise-form .ant-input:focus,
.customise-form .ant-input:hover,
.customise-form .ant-input-focused,
.customise-form .ant-upload .ant-btn-lg:hover,
.customise-form .ant-upload .ant-btn-lg:focus,
.customise-form .ant-input-affix-wrapper:focus,
.customise-form .ant-input-affix-wrapper:hover,
.customise-form .ant-input-affix-wrapper-focused,
.customise-form .react-tel-input .form-control:hover,
.customise-form .react-tel-input .form-control:focus,
.customise-form .react-tel-input:hover .flag-dropdown,
.customise-form .ant-picker-focused,
.customise-form .ant-picker:hover{
	border-color: #57C6D6;
	box-shadow: none;
}
.customise-form .flag-select button{
    padding: 10px 11px;
    background: #fff;
    border-radius: 8px;
}
.customise-form .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector{
    box-shadow: none;
    border-color: #57C6D6;
}
.customise-form .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
	height: auto;
    padding: 7px 11px;
}
.customise-form .ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
	height: 100%;
}
.customise-form .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
	border-color: #57C6D6;
}
.customise-form .ant-input-group-addon{
    border-radius: 5px 0 0 5px;
    background: transparent;
}
.customise-form .ant-input-group-addon .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.customise-form .ant-input-group-addon .ant-select:not(.ant-select-customize-input) .ant-select-selector {
	border: none !important;
}
.ant-select-arrow{
    color: #57C6D6
}
.pet-needs-table-outer{
    padding: 80px 0;
}
.pet-needs-table-outer .table-responsive{
    padding: 40px 0 50px 0;
}
.table.table-pet-needs{
    border-color: #57C6D6;
}
.table.table-pet-needs .package-vital,
.table.table-pet-needs .package-favorite,
.table.table-pet-needs .package-optimum{
    padding: 0;
    width: 130px;
}
.table.table-pet-needs .package-vital .col-inner,
.table.table-pet-needs .package-favorite .col-inner,
.table.table-pet-needs .package-optimum .col-inner{
    width: 100%;
    padding: 10px 0;
    position: relative;
    display: inline-block;
    font-size: 22px;
}
.table.table-pet-needs .package-vital{
    color: #fff;
    background-color: #01749C;
}
.table.table-pet-needs .col-green{
    color: #fff;
    background-color: #57C6D6;
    border-top: 1px solid #fff;
}
.table.table-pet-needs .package-favorite{
    color: #fff;
    padding-top: 10px;
    transform: translateY(-10px);
    background-color: #57C6D6;
}
.table.table-pet-needs .package-favorite .col-inner::after{
    content: "";
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 10px;
    background-color: #57C6D6;
}
.table.table-pet-needs td{
    padding: 15px 10px;
    background: #F7F7FA;
}
.table.table-pet-needs td:first-child{
    color: #858F91;
}
.table.table-pet-needs .col-bg-green{
    background-color: #E6FCFF;
}
.table.table-pet-needs .icon-info{
    float: right;
    color: #57C6D6;
}
.table.table-pet-needs td.empty-cell{
    position: relative;
}
.table.table-pet-needs td.empty-cell .empty {
	float: left;
	width: 101%;
	height: 103%;
	position: absolute;
	top: -1px;
	left: -5px;
	background-color: #fff;
	border-bottom: 1px solid #57C6D6;
}
.table.table-pet-needs .package-favorite.end{
    transform: translateY(10px);
}
.table.table-pet-needs .package-favorite.end .col-inner{
    padding-top: 0;
    font-size: 16px;
}
.table.table-pet-needs .package-favorite.end .col-inner::after {
	bottom: auto;
    top: -20px;
}
.get-quote-wrp .ant-card.plan-box .illness-type{
    font-size: 14px;
}
.name-text{
    display: inline-block;
    margin: 0 10px;
    font-size: 45px;
    font-weight: 700;
    color: #2C6085;
}
.guote-header-right span{
    font-size: 21px;
}
.get-quote-wrp .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow{	
	font-size: 24px;
    color: #57C6D6;
}
.step-wizard-wrp .ant-steps-item-icon{
    position: relative;
}
.step-wizard-wrp .ant-steps-icon{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -55%);
}
.step-wizard-wrp .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon,
.step-wizard-wrp .ant-steps-item-finish .ant-steps-item-icon {
	background: #57C6D6;
    border-color: #57C6D6;
}
.step-wizard-wrp .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
	color: #fff;
}
.step-wizard-wrp  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
	background-color: #57C6D6;
}
.step-wizard-wrp .react-calendar.calendar-techme{
    background-color: #F4F4F4;
}
.step-wizard-wrp .react-calendar.calendar-techme{
    border-color: #F4F4F4;
}
.ant-collapse .anticon svg{
    color: #57C6D6;
}
.points-toknow.ant-collapse{
    background-color: transparent;
}
.section-heading{
    text-align: center;
    border-radius: 10px;
    padding: 25px 20px;
    font-size: 30px;
    font-weight: 600;
    color: #2C6085;
    background-color: #F5F5F5;
}
.section-heading.bg-green{
    color: #fff;
    background-color: #2C6085;
}
.points-toknow.ant-collapse .ant-collapse-header{
    font-size: 24px;
    color: #57C5D5;
}
.points-toknow.ant-collapse.ant-collapse-borderless .ant-collapse-item{
    border-bottom: none;
}
.points-toknow ul{
    margin: 0;
    padding: 0;
    list-style: none;
}
.points-toknow ul li{
    color: #3A3C3E;
    padding-left: 25px;
    position: relative;
    margin: 8px 0;
    font-size: 22px;
    display: inline-block;
    width: 100%;
}
.points-toknow ul li::before{
    content: "";
    position: absolute;
    left: 0;
    top: 10px;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #4DB2C7;
}
.points-toknow.dot-center ul li::before{
    top: 50%;
    transform: translateY(-50%);
}
.points-toknow.rounded-check ul li{
    padding-left: 40px;
}
.points-toknow.rounded-check ul li::before{
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 28px;
    height: 28px;
    top: 50%;
    transform: translateY(-50%);
    background: url("../images/icon-check-rounded-green.png") no-repeat;
}
.points-toknow.rounded-check.red-cross ul li::before{
    background: url("../images/icon-red-cross.png") no-repeat;
}
.points-toknow ul.red-dots li::before{
    background-color: #ED1752;
}
.dropdown-link a{
    text-decoration: none;
}
.steper {
	display: inline-block;
    position: relative;
    height: 23px;
    background-color: #f7f7fa;
    border: 2px solid #bababb;
}
.steper:before{
    content: "";
    position: absolute;
    top: 50%;
    left: -6px;
    width: 42px;
    height: 65px;
    background: url('../images/union-tail.png') no-repeat;
    background-size: 100% 100%;
    transform: translateY(-50%);
}
.steper::after{
    content: "";
    position: absolute;
    top: 50%;
    right: -6px;
    width: 42px;
    height: 65px;
    background: url('../images/union-tail-end.png') no-repeat;
    background-size: 100% 100%;
    transform: translateY(-50%);
}
.step-inner {
	display: inline-block;
	position: relative;
	text-align: center;
	padding-top: 25px;
	width: 100px;
}
.step-dot {
	display: inline-block;
	width: 30px;
	height: 30px;
	border-radius: 100%;
	background-color: #E0E7EB;
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 9;
	transform: translate(-50%, -56%);
}
.step-inner strong {
	position: absolute;
	bottom: -45px;
	left: 50%;
	transform: translateX(-50%);
	width: 100px;
}
.step-inner:first-child strong{
    text-align: left;
}
.step-inner:last-child strong{
    text-align: right;
}
.pointer{
    cursor: pointer;
}
/* .step-inner:nth-child(2) .step-dot{
    background-color: #57C6D6;
    box-shadow: rgba(255, 255, 255, 255) 0px 1px 2px, rgb(255, 255, 255) 0px 0px 0px 2px;
} */
.step-inner .step-dot.step-slected {
    background-color: #57C6D6;
    box-shadow: rgba(255, 255, 255, 255) 0px 1px 2px, rgb(255, 255, 255) 0px 0px 0px 2px;
}

.step-inner:last-child:after{
    display: none;
}
.border-vtcl-lg{
    height: 100px;
    border-left: 1px dashed #E1E7EC;;
}
.add-pet {
	position: absolute;
	bottom: -50px;
	left: 50%;
	transform: translateX(-50%);
}
.add-pet.position-static{
    transform: translateX(0);
}
.add-pet .ant-btn{
    height: auto;
    font-size: 21px;
}
.add-pet .icon {
    width: 30px;
	height: 30px;
	top: -23px;
	left: 50%;
    position: absolute;
	border-radius: 100%;
	background-color: #fff;
	transform: translateX(-50%);
}
.add-pet i.fa-plus-circle{
    font-size: 30px;
}

.send-quote .ant-image {
	width: 20px;
	top: 2px;
	margin-right: 6px;
}
.wrapper .ant-radio-inner:after {
    top: 81%;
    left: 81%;
	background-color: #57C6D6;
    transform: translate(-50%, -50%);
}
.wrapper .ant-radio-checked::after{
    border-color: #57C6D6;
}
.ant-radio-wrapper:hover .ant-radio, 
.ant-radio:hover .ant-radio-inner, 
.ant-radio-input:focus + .ant-radio-inner,
.ant-radio-checked .ant-radio-inner {
	border-color: #57C6D6;
}
.ant-collapse-item .ant-collapse-arrow svg{
    transform: rotate(90deg) !important;
}
.ant-collapse-item.ant-collapse-item-active .ant-collapse-arrow svg{
    transform: rotate(-90deg) !important;
}
.wrapper .ant-switch-checked{
    background-color: #57C6D6;
}
.paymemt-breakdown .ant-image {
	width: 20px;
}
.paymemt-breakdown .name-text{
    font-size: 20px;
}
.breakdown-details {
	padding: 15px;
}
.breakdown-details .detail-inner{
    padding: 0;
    list-style: none;
    margin: 0;
}
.breakdown-details .detail-inner li{
    margin: 5px 0;
    color: #57C6D6;
    font-size: 18px;
}
.breakdown-details .detail-inner li span{
    float: right;
}
.breakdown-details.bordered{
    box-shadow: none;
}
.breakdown-details.bordered .detail-inner li{
    padding: 0 0 10px 0;
    margin: 0 0 10px 0;
    border-bottom: 1px solid #ddd;
}
.paymemt-breakdown .pet-name .ant-typography{
    float: right;
    margin: 0;
}
.pet-name .ant-image{
    cursor: pointer;
}
.bg-gray{
    background-color: #F4F5F7 !important;
}
.bg-gray-light{
    background-color: #F7F7FA !important;
}
.bg-green{
    background-color: #57C5D5 !important;
}
.ant-modal-header{
    background: #57C5D5 !important;
    border-radius: 5px 5px 0 0;
}
.ant-modal-title{
    color: #fff;
    font-size: 20px;
    font-weight: 600;
}
.ant-modal-close-x{
    line-height: 46px;
}
.ant-modal-content{
    border-radius: 5px;
}
.pet-cloud-login{
    padding: 80px 0 300px 0;
    background: url("../images/bg-pet-login.png") no-repeat top center;
    background-size: cover;
}
.pet-cloud-login{
    height: 1000px;
}
.pet-cloud-login .bg-img{
    width: 100%;
    height: 100%;
}
.login-inner{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 100px 0;
}
.login-inner h1{
    font-size: 55px;
    font-weight: 700;
}
.login-inner h4{
    font-size: 36px;
    font-weight: 300;
}
.ant-input-lg,
.ant-picker-large,
.customise-form .ant-input-affix-wrapper-lg{
    padding: 13px 11px;
}
.ant-modal .customise-form .ant-input{
    color: #00749C;
}
.pet-cloud-login .footer-login{
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 0;
}
.pet-cloud-login .footer-stripe{
    background-color: #EEF5F9;
    opacity: 0.88;
}
.pet-cloud-login .footer-stripe .social-links{
    float: right;
}
.pet-cloud-login .footer-stripe .social-links ul li a {
	width: 50px;
	height: 50px;
	font-size: 20px;
	line-height: 50px;
    color: #01749C;
	border: 1px solid #01749C;
}
.pet-cloud-login .footer-stripe .social-links ul li a:hover{
    color: #fff;
    background-color: #01749C;
}
.login-box{
    box-shadow: 0px 2px 13px rgba(0, 0, 0, 0.201431);
}
.login-box .ant-card{
    border-radius: 10px;
}
.ant-layout.dashboard .ant-layout-header {
	line-height: normal;
	background-color: #00749C;
	padding: 15px 25px;
	align-items: center;
    min-height: 72px;
    height: auto;
	flex-direction: row;
    z-index: 999;
	justify-content: space-between;
}
.content-middle{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.sidebar-content{
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    text-align: center;
    transform: translateY(-50%);
}
.pet-box ul{
    margin: 0;
    display: inline-block;
    padding: 0;
}
.pet-box ul li {
    float: left;
    width: 70px;
	height: 70px;
    margin: 0 5px;
	list-style: none;
    position: relative;
	border-radius: 27px;
    border: 1px solid transparent;
}
.pet-box ul li .ant-image{
    overflow: hidden;
    border-radius: 27px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0716783);
}
.pet-box ul li .pet-name{
    position: absolute;
    bottom: -50px;
    left: 0;
    color: #fff;
    width: 100%;
}
.pet-box ul li:last-child{
    color: #fff;
    cursor: pointer;
    line-height: 67px;
    border: 1px dashed #fff;
}
.middle .ant-layout-content{
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    padding-left: 150px;
}
.middle.home .ant-layout-content,
.middle.pet-cloud-contact .ant-layout-content{
    padding-left: 0;
}
.home-cards {
	max-width: 600px;
	display: flex;
	justify-content: center;
	width: 100%;
}
.home-cards .card-outer{
    margin: 10px 20px;
    width: 50%;
}
.dashboard .bg-paws{
    background: url("../images/bg-paw.png") no-repeat center center;
    background-size: 70%;
}
.dashboard .bg-paws2{
    background: url("../images/paws-contact.png") no-repeat center center;
    background-size: 70%;
}
.dashboard-header .menu-dashboard {
	margin-left: auto;
	display: flex;
    padding: 0 20px;
	justify-content: space-between;
}
.dashboard-header .menu-dashboard a {
	position: relative;
	text-decoration: none;
	font-weight: 700;
	font-size: 16px;
    color: #fff;
}
.dashboard-header .menu-dashboard a.active:after,
.header .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item a.active::after,
.header .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item.active a::after{
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    margin-top: 7px;
    background-color: #ED1752;
    transform: translateX(-50%);
}
.custom-shadow{
    filter: drop-shadow(5px 5px 30px rgba(0, 0, 0, 0.1));
}
.dashboard-footer{
    float: left;
    width: 100%;
    line-height: normal;
	background-color: #00749C;
	padding: 15px 25px;
	align-items: center;
    min-height: 72px;
}
.dashboard-footer .social-links{
    float: right;
}
.ant-layout-content.home-content{
    padding: 150px 1rem !important;
}

.ant-layout.dashboard .ant-layout-sider.dashboad-nav {
	background: #fff;
}
.dashboad-nav ul.ant-menu{
    padding-top: 30px;
}
.dashboad-nav ul.ant-menu li.ant-menu-item{
    float: left;
    width: 100%;
    height: auto;
    margin: 5px 0;
    line-height: normal;
}
.dashboad-nav ul.ant-menu li.ant-menu-item img{
    width: 28px;
    /* height: 27px; */
    margin: 0 10px 0 0;
}
.dashboad-nav ul.ant-menu li.ant-menu-item a{
    text-decoration: none !important;
}
.dashboad-nav ul.ant-menu li.ant-menu-item:active, 
.dashboad-nav ul.ant-menu li.ant-menu-item .ant-menu-submenu-title:active {
	background: transparent;
}
.dashboad-nav ul.ant-menu li.ant-menu-item:after{
    display: none;
}
.dashboad-nav ul.ant-menu li.ant-menu-item .ant-menu-title-content{
    padding: 10px;
    border-radius: 5px;
    position: relative;
    display: flex;
    align-items: center;
    height: 45px;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
	background-color: transparent !important;
}
.dashboad-nav ul.ant-menu li.ant-menu-item.ant-menu-item-active:hover .ant-menu-title-content,
.dashboad-nav ul.ant-menu li.ant-menu-item.ant-menu-item-selected .ant-menu-title-content{
    background-color: #57C6D647;
}
.dashboad-nav .ant-menu-item a:hover,
.dashboad-nav ul.ant-menu li.ant-menu-item.ant-menu-item-selected a,
.dashboad-nav ul.ant-menu li.ant-menu-item.ant-menu-item-selected a:hover {
	color: #498D97;
}
.card-content.card-content{
    padding: 20px;
    height: 100%;
    background: #F7F7FA;
    border: transparent;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    transition: all 0.5s ease-out;
}
.card-content.card-content.v1:hover,
.card-content.card-content.v1.active{
    transform: scale(1.1);
    background: #57C5D5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.card-content.card-content .text-green{
    transition: all 0.5s ease-out;
}
.card-content.card-content.v1:hover .text-green,
.card-content.card-content.v1.active .text-green{
    color: #fff !important;
}
.card-content.ant-card .ant-card-head-title,
.card-content.ant-card .ant-card-extra{
    padding-top: 0;
}
.card-content.ant-card .ant-card-head{
    padding: 0 0 0 40px;
    position: relative;
}
.card-content.ant-card .ant-card-body{
    padding: 20px 0 10px 0;
}
.card-content.ant-card .ant-card-extra{
    position: absolute;
    left: 0;    
}
.card-content.ant-card .ant-card-extra img{
    width: 25px;
}
.card-content .ant-card-meta-title{
    white-space: normal;
}
.option-menu {
	display: inline-block;
	width: 100%;
	text-align: center;
}
.option-menu ul {
	list-style: none;
	padding: 0;
	margin: 0;
    display: inline-block;
}
.option-menu ul li {
	float: left;
	margin: 3px;
    min-width: 100px;
}
.option-menu ul li .ant-btn{
    height: auto;
    padding: 0;
}
.option-menu ul li .ant-typography{
    color: #808284;
    font-size: 14px;
    margin-top: 3px;
    font-weight: 400;
}
.date-modify i{
    float: right;
}
.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
	display: none;
}
.ant-table-thead > tr > th {
	background: #F5F6FA;
    border-bottom: none;
    font-weight: 600;
	border-bottom-width: 2px;
}
.ant-table-thead tr td .ant-checkbox-wrapper,
.date-modify{
	color: #858F91;
}
.ant-checkbox-checked .ant-checkbox-inner {
	background-color: #57C6D6;
	border-color: #57C6D6;
}
.ant-checkbox-checked::after,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
	border-color: #57C6D6;
}
.side-menu .ant-drawer-header {
    padding: 8px 13px;
}
.side-menu .ant-drawer-close {
    padding: 10px;
}
.side-menu .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
    border-right: none;
}
.side-menu .ant-menu-item a{
    text-decoration: none;
}
.side-menu .ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
    color: #57C6D6;
    text-decoration: none;
}
.pet-image{
    position: relative;
}
.pet-image::after{
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 78px;
    height: 78px;
    transform: translate(-50%, -50%);
    background: url("../images/icon-cam.png") no-repeat center center;
}
.pet-image.image-uploaded::after{
    display: none;
}
.pet-image img{
    width: 100%;
}
.card-content.ant-card .ant-card .ant-card-head-title{
    padding: 16px 0;
}
.card-content.ant-card .ant-card .ant-card-head{
    padding: 0 24px;
}
.pet-card{
    border-radius: 10px;
    border-color: #E0E0E0;
    box-shadow: 0px 2px 3px 0px #0000000A;
}
.pet-card .box-inner {
	border: 1px solid #ddd;
	padding: 13px 0;
	border-radius: 4px;
    margin: 20px 0;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0716783);
}
.pet-card .box-inner .ant-typography{
    margin: 0;
}
.contact_info{
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.0484594);
}
.claims-page .customise-form .ant-radio-group {
	width: auto;
}
.claims-page .ant-radio-group .ant-radio-button-wrapper {
	width: 110px;
    height: auto;
    border-color: #57C6D6;
}
.pet-info-inner{
    display: flex;
    align-items: center;
    position: relative;
}
.pet-info-inner .ant-btn {
	position: absolute;
	right: 5px;
	top: -5px;
	width: 110px;
    cursor: auto !important;
}
.pet-info-inner figure {
	float: left;
	width: 70px;
	height: 70px;
	list-style: none;
	position: relative;
	border-radius: 27px;
}
.pet-info-inner figure img{
    border-radius: 27px;
}
.pet-info-inner .text{
    margin: 0 0 0 10px;
}
.progress-steps {
	display: flex;
	flex-direction: row;
    overflow: hidden;
    margin: 0 0 10px 0;
	justify-content: space-between;
}
.step-col {
	width: 22px;
	height: 22px;
    z-index: 2;
    position: relative;
	border-radius: 100%;
    text-align: center;
    color: #fff;
	background-color: #57C6D6;
}
.step-col::before {
	content: "";
	position: absolute;
	top: 50%;
	left: 0;
	width: 9999px;
	height: 6px;
	z-index: -1;
	border-radius: 50px;
	background-color: #57C6D6;
	transform: translateY(-50%);
}
.step-col:last-child:before{
    display: none;
}
.step-col.completed span{
    display: none;
}
.step-col.completed::after{
    content: "\f00c";
    position: absolute;
	top: 50%;
	left: 50%;
    font-family: 'FontAwesome';
    transform: translate(-50%, -50%);
}
.btn-add {
	position: absolute;
	right: 30px;
	bottom: 20px;
	border-radius: 100%;
	width: 40px;
	height: 40px;
	text-align: center;
	padding: 0;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3) !important;
}
.contact-option .ant-btn{
    font-size: 24px;
}
.circle-box{
    width: 100%;
    text-align: center;
    display: inline-block;
}
.circle-box figure{
    display: inline-block;
    width: 100px;
    height: 100px;
    text-align: center;
    line-height: 90px;
    margin-bottom: 5px;
    overflow: hidden;
    padding: 20px;
    border-radius: 100%;
    border: 2px solid #00749C;
}
.circle-box figure img{
    display: block;
    width: 100%;
}
.contact-text-block {
	background: #F7F7FA;
	box-shadow: 2.09853px 2.09853px 12.5912px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
	padding: 20px;
	height: 100%;
	font-size: 24px;
	margin: 70px 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.contact-text-block small{
    font-size: 14px;
    font-weight: 300;
}
.find-location-modal .pac-target-input{
    float: left;
}
.find-location-modal .pac-target-input:focus{
    box-shadow: none;
}
.upload-id-card .ant-btn,
.upload-id-card .ant-upload.ant-upload-select{
    width: 100%;
}
.digital-exp{
    margin: 140px auto;
    width: 60%;
    z-index: 9;
}
.exp-box-inner{
    z-index: 2;
    cursor: pointer;
}
.digital-exp:after {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	width: 800px;
	height: 626px;
	background: url("../images/pet-insurance-banner-plain.png") no-repeat center center;
	background-size: contain;
	transform: translate(-55.5%, -53%);
}
.exp-img-block{
    display: inline-block;
    width: 36%;
    margin: 0 4%;
}
.exp-img-block img{
    transition: transform 0.8s;
    transform-style: preserve-3d;
}
.exp-img-block:hover img{
    transform: rotateY(180deg);
}
.exp-img-block p {
	font-size: 23px;
	font-weight: 600;
}
.tooltip-content {
    display: inline-block;
	font-size: 20px;
	text-align: center;
    width: 100%;
}
.ant-layout-footer.footer{
    color: #fff;
    padding: 0;
    background-color: #01749C;
}
.about-us-video{
    height: 100%;
  width: 100%;
}
.footer-nav{
    float: left;
    width: 100%;
    margin: 30px 0 40px 0;
}
.footer-nav .quick-links{
    text-align: center;
    margin: 0;
    padding: 0;
}
.footer-nav .quick-links li{
    display: inline-block;
    margin: 0 35px;
}
.footer-nav .quick-links li a{
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    text-decoration: none;
}
.footer-nav .quick-links li a:hover{
    color:#000;
}
.footer-links a{
    font-size: 16px;
    font-weight: 700;
}
.ant-btn-lg{
    height: 50px;
}
.social-links {
	display: inline-block;
}
.social-links ul {
	padding: 0;
	list-style: none;
}
.social-links ul li {
	float: left;
	margin: 0 20px;	
	text-align: center;
}
.social-links ul li a{
    font-size: 30px;
    display: block;
}
.bottom-footer{
    color: #fff;
    position: relative;
}
.bottom-footer .ant-image{
    position: absolute;
    bottom: 20px;
    right: 0;
    background-color: #fff;
    border-radius: 5px;
    padding: 4px;
    width: 160px;
}

.bottom-footer .underwriter-text{
    text-align: justify !important;
}

.bottom-footer .insurer-logo-holder{
    position: relative;
    background-color: #fff;
    border-radius: 5px;
    padding: 4px;
    width: 130px;
    display: inline-block;
}


.ant-upload-list-item-card-actions{
    /* visibility: hidden; */
}
.personal-info {
	max-width: 800px;
	width: 100%;
    padding: 0 15px 0 0;
}
.renewal-initiator .pet-image img,
.personal-info .user-image img{
    width: 160px;
    height: 160px;
    border-radius: 100%;
}
.personal-info .ant-card-head{
    padding: 20px !important;
    background-color: #57C5D5;
}
.personal-info .ant-card-body{
    background-color: #F7F7FA;
}
.personal-info .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap{
    justify-content: center;
}
.personal-info .ant-tabs .ant-tabs-ink-bar{
    display: none;
}
.personal-info .ant-tabs-nav .ant-tabs-tab {
	padding: 16px;
	flex: 0 0 210px;
	max-width: 210px;
    justify-content: center;
}
.personal-info .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list{
    width: 100%;
    justify-content: center;
}
.personal-info .ant-tabs-tab{
    color: #00749C !important;
    background-color: #fff;
    border-radius: 5px;
    font-weight: 800;
}
.personal-info .ant-tabs-tab-active{
    color: #fff !important;
    background-color: #00749C;
}
.personal-info .ant-tabs-top .ant-tabs-nav{
    margin: 0;
}
.personal-info .ant-card-head .ant-tabs{
    margin-bottom: auto;
}
.personal-info .ant-tabs-top .ant-tabs-nav::before{
    display: none;
}
.personal-info .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    text-shadow: none;
    color: inherit;
}
.personal-info .ant-card {
	font-size: 16px;
	border-radius: 8px;
	overflow: hidden;
    filter: drop-shadow(5px 5px 30px rgba(0, 0, 0, 0.1));
}
.info-inner .btn-edit{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
}
.personal-info .ant-card-bordered {
	border-color: #57C5D5;
}
.list-with-icon {
	float: left;
	width: 100%;
	position: relative;
    text-align: left;
}
.list-with-icon figure {
	float: left;
	width: 30px;
	position: absolute;
	margin: 0;
	top: 50%;
	left: 0;
    text-align: center;
	transform: translateY(-50%);
}
.list-with-icon .text {
	margin: 0 0 0 40px;
	color: #000;
}
.card-sidebar {
	position: absolute;
	top: 0;
	left: -15px;
	height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    z-index: 99;
    width: 150px;
}
.card-sidebar a{
    margin: 10px 0;
}
.card-sidebar h2.ant-typography{
    font-size: 22px;
}
.ant-card.green{
    overflow: hidden;
    border-radius: 5px;
    border-color: #57C5D5;
}
.ant-card.green .ant-card-head{
    background: #00749C;
    border: none;
}
.ant-card.light-green{
    overflow: hidden;
    border-radius: 10px;
    height: 100%;
    border-color: transparent;
}
.ant-card.light-green .ant-card-head{
    background: #57C5D5;
    border: none;
}
.table-scroll {
	max-height: 350px;
	overflow-y: scroll;
}
.noheader .ant-table-header,
.noheader .ant-table-thead{
    display: none;
}
.noborder .ant-table-tbody > tr > td {
	border-bottom: none;
}
.container.container-sm{
    max-width: 900px;
}
.pet-selction-card .ant-radio-button-wrapper{
    height: auto;
    padding: 8px 15px;
    min-width: 200px;
    line-height: normal;
    border-width: 2px;
    border-radius: 10px;
    color: #00749C;
    box-shadow: none !important;
    background-color: #F7F7FA;
    border-color: #00749C !important;
    display: block;
}
.pet-selction-card .ant-radio-button-wrapper span{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.pet-selction-card .ant-radio-button-wrapper figure{
    float: left;
    width: 40px;
    margin: 0;
    height: 40px;
    overflow: hidden;
    border-radius: 100%;
}
.pet-selction-card .ant-radio-button-wrapper figure img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.pet-selction-card .ant-radio-button-wrapper .text{
    font-size: 20px;
    margin: 0 0 0 5px;
    text-align: center;
}
.pet-selction-card .ant-radio-button-wrapper:not(:first-child)::before {
	display: none;
}
.pet-selction-card .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.pet-selction-card .ant-radio-button-wrapper:hover{
	color: #ED1752;
}
.pet-selction-card .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.pet-selction-card .ant-radio-button-wrapper:hover {
	border-color: #ED1752 !important;
}
.my-pet-detail {
	display: flex;
	flex-direction: row;
    margin-bottom: 50px;
}
.my-pet-detail figure{
    margin: 0;
    position: relative;
}
.my-pet-detail figure img{
    width: 186px;
    height: 186px;
    display: flex;
    border-radius: 100%;
    position: relative;
    object-fit: cover;
    object-position: center;
}
.my-pet-detail figure img.arrow-img{
    width: auto;
    height: auto;
    border-radius: inherit;
}
.my-pet-detail .text{
    padding: 0 50px 0 30px;
}
.my-pet-detail .text .ant-card{
    border-radius: 10px;
    border-color: #57C5D5;
}
.my-pet-detail .text .ant-card .ant-card-body{
    padding: 10px 5%;
}
.pet-characteristics{
    width: 140px;
    height: 140px;
    display: flex;
    align-items: center;
    border-radius: 100%;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    border: 1px solid #00749C;
}
.pet-characteristics .color-circle{
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    background-color: #000;
}
.my-pets .ant-card.light-green .ant-card-body{
    padding: 24px 10px;
}
.my-pets .ant-card.light-green .ant-card-head{
    padding: 20px 15px;
}
.my-pets .pet-selction-card .ant-radio-button-wrapper span{
    justify-content: center;
    position: relative;
    padding-left: 20px;
}
.my-pets .pet-selction-card .ant-radio-button-wrapper span figure{
    position: absolute;
    left: 0;
}
.my-pets .pet-selction-card .ant-radio-button-wrapper{
    margin: 10px 0 !important;
}
.my-pets .pet-selction-card .ant-radio-button-wrapper .text {
	font-size: 18px;
    margin: 0;
}
.inputEditable,
.inputEditable:focus{
    border: none;
    box-shadow: none;
    font-weight: 600;
    font-size: 20px;
    line-height: 1.4;
    text-align: center;
    background-color: transparent;
}
.labelInputEditable{
    font-weight: 600;
    font-size: 20px;
    line-height: 1.4;
    width: 160px;
    position: relative;
    text-overflow: ellipsis;
    overflow: visible;
}
.labelInputEditable::after{
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    width: 20px;
    height: 20px;
    transform: translateY(-150%);
    background: url("../images/icon-pencil-white.png") no-repeat center center;
    background-size: cover;
}

.claim-wizard .steps-content {
    position: relative;
}
.claim-wizard .steps-content::after{
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    width: 310px;
    height: 600px;
    transform: translateY(-50%);
    background: url("../images/step1-mortality-benefit.png") no-repeat center center;
    background-size: contain;
}
.claim-wizard .steps-content.step2::after{
    background: url("../images/step2-mortality-benefit.png") no-repeat center center;
    background-size: contain;
}
.claim-wizard .steps-content.step3::after{
    background: url("../images/step3-mortality-benefit.png") no-repeat center center;
    background-size: contain;
}
.claim-wizard .steps-content.step4::after{
    background: url("../images/step4-mortality-benefit.png") no-repeat center center;
    background-size: contain;
}
.claim-wizard .steps-content.step5::after{
    background: url("../images/step5-mortality-benefit.png") no-repeat center center;
    background-size: contain;
}
.claim-wizard .steps-content.step6::after{
    background: url("../images/step6-mortality-benefit.png") no-repeat center center;
    background-size: contain;
}
.claim-wizard .steps-content.step7::after{
    background: url("../images/step7-mortality-benefit.png") no-repeat center center;
    background-size: contain;
}
.claim-wizard .steps-content.step8::after{
    background: url("../images/step8-mortality-benefit.png") no-repeat center center;
    background-size: contain;
}
.pet-pic {
	position: absolute;
	top: -30px;
	right: -30px;
}
.claim-wizard .ant-card{
    position: relative;
    z-index: 9;
    /* background-color: transparent; */
}
.claim-wizard .card-sidebar .ant-card{
    padding: 20px !important;
}
.claim-wizard .pet-selction-card{
    margin-bottom: 0 !important;
}
.claim-wizard .pet-selction-card .ant-radio-button-wrapper{
    min-width: 137px;
    margin: 10px 25px !important;
    border-color: transparent !important;
    background-color: transparent;
}
.claim-wizard .pet-selction-card .ant-radio-button-wrapper span {
	flex-direction: column;
}
.claim-wizard .pet-selction-card .ant-radio-button-wrapper figure{
    width: 127px;
    height: 127px;
    margin: 0 0 10px 0;
    border: 3px solid transparent;
    transition: all 0.2s ease-in-out 0s;
}
.claim-wizard .pet-selction-card .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) figure {
	border-color:#ED1752 !important;
}
.ant-btn.btn-wizard {
	width: 55px;
	height: 55px;
	color: #57C5D5;
	text-align: center;
	padding: 2px;
	font-size: 35px;
	position: relative;
	border-color: #57C5D5;
}
.ant-btn.btn-wizard:hover{
    color: #fff;
    border-color: #57C5D5;
    background-color: #57C5D5;
}
.ant-btn.btn-wizard i {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 20px;
	height: 20px;
	line-height: 18px;
}
.custom-radio .ant-radio-wrapper{
    font-size: 25px;
    margin: 5px 0;
    text-align: left;
    font-family: 'AvenirLTStdLight';
}
.custom-radio .ant-radio-inner{
    width: 28px;
    height: 28px;
    border-color: #808284;
}
.custom-radio .ant-radio-inner:before {
	position: absolute;
	top: 50%;
	left: 50%;
	display: block;
	width: 16px;
	height: 16px;
	background-color: transparent;
    border: 1px solid #808284;
	border-radius: 100%;
    transform: translate(-50%, -50%);
	content: '';
}
.custom-radio .ant-radio-inner::after {
    width: 14px;
    height: 14px;
    border-radius: 100%;
    background-color: #ED1752;
}
.custom-radio .ant-radio-wrapper:hover .ant-radio,
.custom-radio .ant-radio:hover .ant-radio-inner,
.custom-radio .ant-radio-input:focus + .ant-radio-inner,
.custom-radio .ant-radio-checked .ant-radio-inner {
	border-color: #808284;
}
.custom-datefield.ant-input{
    min-width: 300px;
    width: 100%;
}
.custom-datefield.ant-input,
.ant-picker.timepicker {
	border-color: #00749C;
	background: #F7F7FA;
	height: 50px;
    font-size: 20px;
    color: #00749C;
	border-radius: 10px;
    box-shadow: none;
    margin: 0 0 40px 0;
}
.ant-picker.timepicker .ant-picker-input > input{
    font-size: 20px;
    color: #00749C;
}
.ant-picker-dropdown .ant-picker-ok .ant-btn-primary{
    background-color: #00749C;
    border-color: #00749C;
    color: #fff;
}
.ant-picker-dropdown .ant-picker-now .ant-picker-now-btn{
    color: #00749C;
}
.ant-picker.timepicker {	
	width: auto;
}
.react-datepicker{
    max-width: 460px;
    width: 100%;
    border-color: #00749C;
    border-width: 2px;
}
.react-datepicker__month-container{
    width: 100%;
}
.react-datepicker__day-names{
    display: none;
}
.react-datepicker__navigation{
    height: 46px;
    line-height: 20px;
}
.react-datepicker__header{
    padding: 10px 0;
    background-color: transparent;
    border-bottom: transparent;
}
.react-datepicker__current-month{
    font-weight: 800;
    color: #00749C;
    font-size: 20px;
    font-family: 'Nunito', sans-serif !important;
}
.react-datepicker__day{
    width: 12.5%;
    color: #00749C;
    border-radius: 0;
    font-weight: 700;
    background-color: #F7F7FA;
}
.react-datepicker__day:hover{
    border-radius: 0;
    background-color: #e0e0e2;
}
.react-datepicker__day--today{
    font-weight: 800;
}
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__day--keyboard-selected{
    color: #fff;
    background-color: #57C5D5;
}
.react-datepicker__day--outside-month{
    opacity: 0.5;
}
.react-datepicker__navigation-icon::before{
    border-color: #ED1752;
}
.payment-field{
    border-color: #00749C !important;
	background: #F7F7FA;
	width: 300px;
	height: 50px;
	border-radius: 10px;
    box-shadow: none;
    margin: 0 0 40px 0;
}
.payment-field .ant-input{
    color: #00749C;
    font-size: 20px;
    font-family: 'AvenirLTStdLight';
    background-color: transparent;
}
.payment-field .ant-input-suffix{
    font-weight: 400;
    font-size: 20px;
}
.warning-text{
    position: relative;
    padding: 0 0 0 50px;
}
.warning-text img{
    position: absolute;
    top: 5px;
    left: 0;
}
.warning-text .ant-typography{
    font-size: 20px;
}
.spinner-wrp.ant-spin-spinning{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    background-color: rgb(255, 255, 255, 0.5);
}
.upload-doc .ant-upload-list-text-container{
    text-align: left;
}
.upload-doc .ant-btn,
.upload-doc .ant-btn:hover{
    display: inline-block;
    width: 137px;
    height: 76px;
    padding: 10px 0;
    border-width: 3px;
    border-radius: 10px;
    border-color: #808284;
    background-color: #F7F7FA;;
}
.upload-doc .ant-btn div{
    margin: 0 0 10px 0;
}
.upload-doc .ant-btn span{
    color: #808284;
}

.ant-upload-list-item-name{
    width: 300px;
}

.add-comments textarea,
.add-comments textarea:focus,
.add-comments textarea:hover{
    height: 180px;
    padding: 15px 0;
    border-width: 3px !important;
    border-radius: 10px;
    border-color: #00749C;
    color: #00749C;
    text-align: center;
    font-size: 22px;
    box-shadow: none !important;
    background-color: #F7F7FA;
}
.card-customize-plan .ant-card-head {
	border-radius: 10px;
	border: 1px solid #D6D6D9;
	background: #F1F1F4;
	filter: drop-shadow(5px 5px 30px rgba(0, 0, 0, 0.1));
}
.card-customize-plan .ant-card-body{
    border-radius: 10px;
    border: 1px solid #D6D6D9;
    padding: 30px 6px;
}
.ant-card.card-customize-plan {
	border: none;
}
.recomended-plan{
    position: relative;
}
.recomended-plan::before{
    content: "";
    position: absolute;
    top: 50%;
    left: -50px;
    width: 38px;
    height: 58px;
    transform: translateY(-60%);
    background: url("../images/curve-arrow.png") no-repeat center left;
}
.recomended-plan .text-line{
    font-size: 36px;
    font-weight: 300;
    line-height: 30px;
    margin: 0 0 5px 0;
}
.section-gray{
    display: inline-block;
    width: 100%;
    background: #F7F7FA;
    box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 30px 10px;
    margin: 0 0 20px 0;
}
.section-gray .section-heading{
    padding: 0;
    background-color: transparent;
}
.pet-summary.price-selector.active{
    background-color: #57c6d6;
}
.pet-summary.price-selector.active .text-light-green,
.pet-summary.price-selector.active .notes
{
    color: #F7F7FA !important;
}

.pet-summary .pet-name{
    display: flex;
    align-items: center;
}
.pet-summary .price-amount{
    font-size: 45px;
    font-weight: 700;
    color: #2C6085;
    line-height: 45px;
}
.pet-summary sub,
.pet-summary .payment-period{
    font-size: 18px;
    color: #2C6085;
    font-weight: 700;
}
.payment-period{
    padding-left: 20px;
}
.total-amount{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 12px;
    font-size: 30px;
    font-weight: 3500;
    color: #fff;
    background: #57C5D5;
    border-radius: 10px;
    gap: 50px;
}
.total-amount .price-amount{
    font-size: 50px;
    font-weight: 700;
}
.custom-radiosingle-radio{
    display: flex;
    align-items: center;
    font-size: 18px;
}
.custom-radiosingle-radio .ant-radio{
    top: 0;
}
.important-date ul{
    list-style: none;
    font-size: 22px;
    padding: 0;
}
.important-date ul li{
    color: #3A3C3E;
    margin: 0 0 40px 0;
}
.important-date .notes{
    font-style: italic;
    text-align: justify;
    font-size: 18px;
    font-weight: 300;
    margin-top: 80px;
}
.important-date .ant-card{
    border-width: 2px;
    border-color: #2C6085;
    border-radius: 10px;
}
.important-date .notes .ant-image{
    width: 100%;
    text-align: center;
    margin: 0 0 15px 0;
}
.important-date .notes .ant-image-img{
    width: auto;
    display: inline-block;
}
.section-what-next .ant-typography{
    word-spacing: 8px;
}
.more-doc-upload {
	width: 100%;
	display: inline-block;
	position: relative;
	padding-bottom: 26px;
}
.more-doc-upload .ant-upload.ant-upload-select {
	display: inline-block;
	position: absolute;
	top: auto;
	width: 100%;
	left: 0;
	z-index: 9;
	bottom: 0;
}
.more-doc-upload .ant-btn{
    background: transparent;
    border: none;
    padding: 0;
    font-size: 25px;
    color: #2C6085;
    font-weight: 600;
    margin: 0 0 15px 0;
}
.more-doc-upload .ant-btn:focus{
    box-shadow: none !important;
}
.more-doc-upload .ant-btn span{
    margin: 0;
    text-decoration: underline;
}
.more-doc-upload .ant-btn .anticon-upload{
    display: none;
}
.more-doc-upload .ant-upload-list{
    margin: 0 0 30px 0;
}
.more-doc-upload .ant-upload-list,
.more-doc-upload .ant-upload-list .ant-upload-list-text-container{
    display: inline-block;
    width: 100%;
}
.more-doc-upload .ant-upload-text-icon{
    text-indent: -999px;
    width: 38px;
    height: 21px;
    background: url("../images/icon-clicp.png") no-repeat center center;
}
.more-doc-upload .ant-upload-list-text .ant-upload-span, 
.more-doc-upload .ant-upload-list-picture .ant-upload-span {
	font-size: 20px;
    color: #00749C;
}
.more-doc-upload .ant-upload-list-item{
    display: inline-block;
}
.more-doc-upload .ant-upload-list-item:hover .ant-upload-list-item-info {
	background-color: transparent;
}
.full-width-section{
    padding: 80px 0;
}
.pets-cloud .banner-caption{
    display: flex;
    align-items: center;
    justify-content: end;
}
.things-toknow{
    position: relative;
    padding: 0 0 0 100px;
    text-align: justify;
}
.things-toknow::before{
    content: "";
    position: absolute;
    top: 10px;
    left: 0;
    width: 53px;
    height: 53px;
    background: url("../images/icon-check-green.png") no-repeat;
}
.things-toknow .text-desc{
    font-size: 20px;
    /* word-spacing: 8px; */
}
.customise-form.calculate-oslo-claim .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector,
.customise-form.calculate-oslo-claim .ant-input{
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
    border: transparent;
}
.customise-form.calculate-oslo-claim .ant-input{
    text-align: center;
}
.customise-form.calculate-oslo-claim .bottom-text{
    font-style: italic;
}
.privacy-policy-content .points-toknow ul li{
    margin: 0 0 50px 0;
}
.privacy-policy-content .points-toknow ul li:last-child{
    margin: 0;
}
.privacy-policy-content div.ant-typography,
.privacy-policy-content .points-toknow ul li ul li{
    font-size: 18px;
}
.privacy-policy-content .points-toknow ul li ul{
    margin: 10px 0;
}
.privacy-policy-content .points-toknow ul li ul li{
    color: #00749C;
    margin: 0;
}
.privacy-policy-content .points-toknow ul li ul li:before {
	background-color: #00749C;
}
.banner.privacy-policy .banner-caption{
    height: auto;
    top: auto;
    bottom: -20px;
}
.banner.content-center .banner-caption{
    display: flex;
    align-items: center;
    justify-content: end;
}
.faqs-content .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
	left: unset;
	right: 0px;
    font-size: 25px;
}
.faqs-content div.ant-typography,
.faqs-content .points-toknow ul li{
    font-size: 18px;
}
.faqs-content .points-toknow ul li:before {
	background-color: #00749C;
    width: 7px;
    height: 7px;
}
.text-underline{
    text-decoration: underline !important;
}
.ant-divider.ant-divider-vertical.custom-divider-vertical {
	border-left: 1px solid #3A3C3E;
	min-height: 120px;
    height: 100%;
}
.vet-address{
    padding: 0 0 0 30px;
}
.vet-address .text-light-green{
    font-size: 20px;
}
.ant-pagination .ant-pagination-item,
.ant-pagination .ant-pagination-item-link{
    border-radius: 100%;
    color: #57C6D6;
    border-color: #57C6D6 !important;
    font-weight: 700;
}
.ant-pagination .ant-pagination-item-active{
    background: #57C6D6;
    color: #fff;
}
.ant-tooltip .ant-tooltip-inner{
    padding: 0;    
	min-width: 220px;    
    padding: 30px 25px;
    border-radius: 10px;
}
.tooltip-content-white .ant-tooltip-inner{
    color: #57C5D5;
}
.text-blue .ant-select-selection-placeholder,
.text-blue .ant-select-single.ant-select-open .ant-select-selection-item,
.text-blue .ant-select-selection-item{
    color: #57C5D5;
}
.text-blue .ant-select-arrow{
    color: #ED1752;
}
.coming-soon{
    transform: rotate(-45deg);
    display: inline-block;
}
.ant-checkbox-wrapper.use-location {
	font-size: 18px;
    color: #3A3C3E;
}

.payment-field .ant-input::placeholder, /* Chrome, Firefox, Opera, Safari 10.1+ */
.add-comments textarea::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #00749C ;
    opacity: 1; /* Firefox */
}

.payment-field .ant-input:-ms-input-placeholder, /* Internet Explorer 10-11 */
.add-comments textarea:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #00749C ;
}

.payment-field .ant-input::-ms-input-placeholder, /* Microsoft Edge */
.add-comments textarea::-ms-input-placeholder { /* Microsoft Edge */
    color: #00749C ;
}
.pet-resource-block{
    display: inline-block;
    position: relative;
}
.pet-resource-block:hover::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, .5);
}
.pet-resource-block .ant-btn{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    display: none;
}
.pet-resource-block:hover .ant-btn{
    display: block;
}


.switch {
    position: relative;
    height: 40px;
    width: 200px;
    background: #fff;
    border-radius: 100px;
    display: inline-block;
    border: 1px solid #d9d9d9;;
}

.btn-sliding{
    position: relative;
}
.btn-sliding.btn-payment-slider{
    width: auto;
}
.customise-form .btn-payment-slider .ant-radio-button-wrapper{
    width:auto;
    border: transparent;
}
.btn-sliding .ant-radio-button-wrapper{
    position: relative;
    z-index: 2;
    background: transparent;
}
.switch-selection {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    display: block;
    width: 50%;
    height: 100%;
    border-radius: 100px;
    background-color: #57C6D6;
    -webkit-transition: left 0.15s ease-out;
    -moz-transition: left 0.15s ease-out;
    -ms-transition: left 0.15s ease-out;
    -o-transition: left 0.15s ease-out;
    transition: left 0.15s ease-out;
}
.btn-sliding.female .switch-selection,
.btn-sliding.cat .switch-selection {
    left: 50%;
    /* Note: left: 50%; doesn't transition in WebKit */
}

.features-table .top-headings{
    margin-bottom: -1px;
}
.features-table .cat-title {
    color: #fff;
    padding: 15px 5px;
	background-color: #01749C;
	margin-bottom: 1px;
    text-align: center;
    font-size: 22px;
    font-weight: 700;
    position: relative;
}
.features-table .cat-title::after{
    content: "";
    position: absolute;
    top: 0;
    left: -2px;
    width: 3px;
    height: 100%;
    background-color: #01749C;
}
.features-table .cat-title.light-blue{
    background-color: #57C6D6;
}
.features-table .cat-title.light-blue::before{
    content: "";
    position: absolute;
    bottom: 100.5%;
    left: -2px;
    width: 100%;
    height: 20px;
    background-color: #57C6D6;
}
.features-table .top-headings.bottom .cat-title.light-blue{
    padding: 0;
}
.features-table .cat-title.light-blue::after{
    background-color: #57C6D6;
}
.features-table .top-headings.bottom .cat-title.light-blue::before {
	height: 20px;
	bottom: -19px;
	left: -2px;
	width: 100.5%;
	border-top: 1px solid #fff;
}
.features-table .icon-info{
    float: right;
    color: #57C6D6;
}
.features-table .ant-collapse,
.features-table .border-bottom,
.features-table .border-end,
.features-table .ant-collapse-content,
.features-table .ant-collapse .ant-collapse-item{
    border-color: #57C6D6 !important;
}
.features-table .border-end.bg-lightblue{
    position: relative;
}
.features-table .bg-lightblue:after{
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #fff;
}
.features-table .ant-col-xl-12 {
	display: block;
	flex: 0 0 50%;
	max-width: 50%;
}
.features-table .ant-col-xl-4 {
	display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
}
.features-table .ant-col-xl-8 {
	display: block;
	flex: 0 0 33.33333333%;
	max-width: 33.33333333%;
}
.features-table .ant-collapse .ant-collapse-item .ant-collapse-header,
.features-table .ant-collapse-content .ant-collapse-content-box{
    padding: 0;
}
.features-table .cell-inner {
	padding: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
    position: relative;
}
.features-table .ant-collapse .anticon {
	position: absolute;
	top: 50%;
	left: 10px;
	transform: translateY(-50%);
	font-size: 18px !important;
}
.features-table .ant-collapse .anticon svg{
    color: #00749C;
}
.features-table .cell-inner.first-col{
    justify-content: inherit;
}
.features-table .cell-inner.first-col .ant-typography{
    width: 100%;
}
.features-table .bg-lightblue{
    width: 100%;
    background-color: #57C6D6;
}
.features-table .ant-collapse .ant-collapse-item .ant-collapse-header .cell-inner.first-col{
    padding-left: 33px;
    height: 100%;
}
.features-table .ant-collapse .ant-collapse-item.ant-collapse-item-disabled .ant-collapse-header .cell-inner.first-col{
    padding-left: 16px;
}
.features-table .ant-collapse .ant-collapse-item.ant-collapse-item-disabled .ant-collapse-header .anticon{
    display: none;
}

.custom-edit{
    position: relative;
    padding-right: 25px;
}

.custom-edit::after{
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    width: 20px;
    height: 20px;
    transform: translateY(-150%);
    background: url("../images/edit-icon.png") no-repeat center center;
    background-size: cover;
}

.custom-edit-black{
    position: relative
}

.custom-edit-black::after{
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    width: 20px;
    height: 20px;
    transform: translateY(-50%);
    background: url("../images/icon-pencil-green.png") no-repeat center center;
    background-size: cover;
}

.custom-edit-black.microchip::after{
    right: 8px;
}

.video-overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.137);
}
.video-overlay-pause{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background: rgba(0, 0, 0, 0);
}
.video-overlay-pause:hover{
    background: rgba(0, 0, 0, 0.137);
}
.video-overlay-pause .video-control-holder,
.video-overlay .video-control-holder{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.video-overlay-pause .video-control-holder{
    display: none;
}
.video-overlay-pause:hover .video-control-holder{
    display: flex;
}
.video-control-holder .txt-button{
    width: 30%;
    font-size: 750%;
}
.video-overlay-pause:hover .video-control-holder .txt-button{
    opacity: 0.5;
}
.title-thin{
    font-weight: 300;
}


.VideoPlayer {
    position: relative;
    width: 500px;
    height: auto;
    display: block;
    margin: 75px auto;
  }
  
  .VideoPlayer .controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 8px;
    left: 5px;
    width: 490px;
    padding: 4px 4px;
    border-radius: 4px;
    background: rgba(30, 30, 30, 0.75);
    visibility: hidden;
    opacity: 0;
    transition: all 0.25s ease-out;
  }
  
  .VideoPlayer:hover > .controls {
    visibility: visible;
    opacity: 1;
  }
  
  /* ==  Play/Pause == */
  .VideoPlayer .play_pause_btn {
    outline: none;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    padding: 5px 15px;
    transition: all 0.15s ease-out;
    background: #00749C !important;
  }
  .VideoPlayer .play_pause_btn:hover {
    background: #00749C !important;
  }
  .VideoPlayer .play_pause_btn .svg_play_pause {
    fill: #fff;
    width: 25px;
    height: 25px;
    position: relative;
    top: 2px;
  }
  
  .VideoPlayer .time {
    color: #fff;
    font-size: 12px;
  }
  
  /* ==  Play/Pause == */
  /* ==  Mute/Unmute == */
  .VideoPlayer .mute_unmute_btn {
    outline: none;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    padding: 5px 15px;
    transition: all 0.15s ease-out;
    background:#00749C !important;
  }
  .VideoPlayer .mute_unmute_btn:hover {
    background: #00749C !important;
  }
  .VideoPlayer .mute_unmute_btn .svg_mute_unmute {
    width: 20px;
    height: 20px;
    position: relative;
    top: 2px;
  }
  .VideoPlayer .mute_unmute_btn .svg_mute_unmute path,
  .VideoPlayer .mute_unmute_btn .svg_mute_unmute polygon,
  .VideoPlayer .mute_unmute_btn .svg_mute_unmute rect {
    fill: #fff;
  }
  .VideoPlayer .mute_unmute_btn .svg_mute_unmute circle {
    stroke: #fff;
    stroke-width: 2;
  }
  
  /* ==  Mute/Unmute == */
  /* ==  Range == */
  .VideoPlayer .volume_range {
    width: 50px !important;
  }
  
  .VideoPlayer  input[type=range] {
    -webkit-appearance: none;
    width: 180px;
    margin: 6px 0;
    background: none;
  }
  
  .VideoPlayer input[type=range]:focus {
    outline: none;
  }
  
  .VideoPlayer input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
    background: #00749C !important;
    border-radius: 1.9px;
    border: 0px solid rgba(1, 1, 1, 0);
  }
  
  .VideoPlayer input[type=range]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
    border: 0px solid rgba(0, 0, 0, 0);
    height: 20px;
    width: 10px;
    border-radius: 4px;
    background: #eeeeee;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -6px;
  }
  
  .VideoPlayer input[type=range]:focus::-webkit-slider-runnable-track {
    background:#00749C !important;
  }
  
  .VideoPlayer input[type=range]::-moz-range-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
    background: #00749C !important;
    border-radius: 1.9px;
    border: 0px solid rgba(1, 1, 1, 0);
  }
  
  .VideoPlayer input[type=range]::-moz-range-thumb {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
    border: 0px solid rgba(0, 0, 0, 0);
    height: 20px;
    width: 10px;
    border-radius: 4px;
    background: #eeeeee;
    cursor: pointer;
  }
  
  .VideoPlayer input[type=range]::-ms-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  
  .VideoPlayer input[type=range]::-ms-fill-lower {
    background: #00749C !important;
    border: 0px solid rgba(1, 1, 1, 0);
    border-radius: 3.8px;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
  }
  
  .VideoPlayer input[type=range]::-ms-fill-upper {
    background: #00749C !important;
    border: 0px solid rgba(1, 1, 1, 0);
    border-radius: 3.8px;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
  }
  
  .VideoPlayer input[type=range]::-ms-thumb {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
    border: 0px solid rgba(0, 0, 0, 0);
    height: 20px;
    width: 10px;
    border-radius: 4px;
    background: #eeeeee;
    cursor: pointer;
    height: 8px;
  }
  
  .VideoPlayer input[type=range]:focus::-ms-fill-lower {
    background: #00749C !important;
  }
  
  .VideoPlayer input[type=range]:focus::-ms-fill-upper {
    background: #00749C !important;
  }
  
  .hide {
    display: none;
  }

  .formiteminline{
    display: inline-block;
  }


  .addapet{
    border: 2px solid #00749C;
    height: unset;
    font-size: 1.2em;
    font-weight: 600;
  }

  .addapet .anticon{
    font-size: 1.5em;
  }

  .renewal-initiator .pet-image{
    text-align: center;
  }

  .renewal-initiator .renewal-data{
    font-size: 22px;
    opacity: 0.8;
  }

  .testimonial{
    width: 100%;
    height: 350px;
    padding: 29px 56px;
  }

  .testimonial .testimonial-glyphe{
    position: relative;
    margin: 0;
    width: 65px;
    height: 52px;
  }
  .testimonial .testimonial-content
  {
    position:relative;
    margin: 26px 0 0;
    width: 398px;
  }
  
  .testimonial .testimonial-content .testimonial-quote
  {
    position:relative;
    height: 80px;
  }

.testimonial .testimonial-content .red-seperator{
    position: relative;
    margin: 55px 0 0;
    width: 77px;
    height: 4px;
    background-color: #ED1752;
    
}

.testimonial .testimonial-content .testimonial-author{
    position: relative;
    margin: 10px 0 0;
    font-weight: 700;
}

.testimonial .testimonial-content .testimonial-author .author-name{
    font-size: 18px;
    display: block;
}

.testimonial .testimonial-content .testimonial-author .author-pet{
    font-size: 14px;
    display: block;
}

.testimonial .testimonial-image-holder{
    position: absolute;
    width: 151.01px;
    height: 152px;
    right: 55px;
    bottom: 55px;

    border: 7px solid #00749C;
    border-radius: 250px;
    overflow: hidden;
}

.testimonial .testimonial-image{
    width:100%;
    height: 100%;
}

.testimonial .testimonial-image .ant-image{
    width:100%;
    height: 100%;
}

.testimonial .testimonial-image .ant-image .ant-image-img{
    width:100%;
    height: 100%;
}

.show-on-small-screens{
    display: none;
}

.show-on-large-screens{
    display: block;
}

.admin-edit-box{
    min-width: 200px;
}

ul.carousel-dots{
    bottom: -20px !important;
}

.carousel-dots li button{
    background-color: #01749C !important;
}

.gulf-times-post{
    background: linear-gradient(225deg, #01749C, #57C6D6);
    border-radius: 25px;
    overflow: hidden;
}

.img-logo-holder-home{
    width:30%;
}

.img-logo-holder-home-small{
    height: 64px;
    width: 100%;
    display: flex;
    justify-content: space-between; /* Ensures items are placed at the start and end */
    align-items: center; /* Vertically aligns items (optional) */
}

.img-logo-holder-home-small .logo-img{
    height: 64px;
    width: auto;
}

.bg-red-title{
    background-color: #ED1752;
    width: auto;
    display: inline-block;
    padding: 10px;
    border-radius: 10px;
}

.add-top-margin-100{
    margin-top: 100px;
}

.add-top-margin-40{
    margin-top: 40px;
}

.add-top-margin-30{
    margin-top: 30px;
}

.add-top-margin-20{
    margin-top: 20px;
}

.add-top-margin-10{
    margin-top: 10px;
}

.add-top-margin-5{
    margin-top: 5px;
}

.add-text-bold{
    font-weight: 800 !important;
}

.follow-up-text{
    font-size: 60px;
    font-weight: 700;
}

.follow-up-sub-text{
    font-size: 35px;
    font-weight: 700;
}

.follow-up-text-small{
    font-size: 30px;
    font-weight: 700;
}

.follow-up-sub-text-small{
    font-size: 20px;
    font-weight: 700;
}

.home-page-quote-holder{
    border-radius: 10px;
}

.payment-selection{
    background: #2C6085;
    height: 100%;
    align-content: center;
    display: flex;
    justify-content: center;

}

.landing-page-image-card{
    border-radius: 10px;
    border: 0px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    background: linear-gradient(
        rgba(1, 117, 156, 0.555), /* Light blue tint with 50% opacity */
        rgba(1, 117, 156, 0.212)
      ),
      url("../images/dog-page-sub-banner.jpg") no-repeat center center;
    background-size: cover; /* Ensures the image fills the container */
    height: 100%;
}

.landing-page-image-card-cat{
    border-radius: 10px;
    border: 0px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    background: linear-gradient(
        rgba(1, 117, 156, 0.555), /* Light blue tint with 50% opacity */
        rgba(1, 117, 156, 0.212)
      ),
      url("../images/cat-page-sub-banner.jpg") no-repeat center center;
    background-size: cover; /* Ensures the image fills the container */
    height: 100%;
}
.landing-page-image-card-cat span,
.landing-page-image-card span{
    color: #fff;
    font-size: 40px;
    font-weight: 700;
}

.landing-page-info-card{
    border-radius: 10px;
    border: 0px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    background-color: #61CFEB45;
    height: 100%;
}
.landing-page-info-card span{
    color: #000;
    font-size: 19px;
    font-weight: 700;
}
.landing-page-info-card .tamara{
    height: 30px;
}

.landing-page-today-offer-card{
    border-radius: 15px;
    border: 0px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
    background-color: rgb(1, 116, 156);
    background: url("../images/bg01.png") repeat, linear-gradient(
        rgb(1, 117, 156), /* Light blue tint with 50% opacity */
        rgba(1, 117, 156, 0.808)
      );
}

.text-size-20{
    font-size: 20px;
}

.text-color-yellow{
    color: #FFD700;
}

.testimonial-image-card{
    aspect-ratio: 4/3;
    border-radius: 15px;
    background-position: center center !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    overflow: hidden;
}

.testimonial-image-card.bg-blue{
    background-color: rgb(1, 117, 156) !important;
}

.full-height{
    height: 100% !important;
}

.border-radius-10{
    border-radius: 10px;
}

.border-radius-15{
    border-radius: 15px;
}

.border-radius-20{
    border-radius: 20px;
}

.bg-lightblue{
    background-color: #01749c1a !important;
}

.bg-real-green{
    background-color: #00883d !important;
    border: #00883d !important;
}

.bg-amber{
    background-color: #FFBF00 !important;
}

.dashboard .container{
    overflow-y: auto;
    overflow-x: hidden;
}

@media (max-width: 960px) {

    .testimonial{
        height: unset;
    }

    .testimonial .testimonial-content
    {
        position:relative;
        margin: 26px 0 0;
        width: auto;
        text-align: center;
    }

    .testimonial .testimonial-content .red-seperator{
        position: relative;
        margin: 55px auto 0;
        width: 77px;
        height: 4px;
        background-color: #ED1752;
        
    }
    
    .testimonial .testimonial-content .testimonial-author{
        position: relative;
        margin: 10px auto 0;
        font-weight: 700;
    }

    .testimonial .testimonial-image-holder{
        position: relative;
        margin: 20px auto;
        width: 100px;
        height: 100px;
    
        right: unset;
        bottom: unset;

        border: 7px solid #00749C;
        border-radius: 250px;
        overflow: hidden;
    }

    .testimonial .testimonial-content .testimonial-quote
    {
        position:relative;
        min-height: 100px;
        height: auto;
    }

    .btn.btn-custom{
        min-width: 150px;
    }

    .show-on-small-screens{
        display: block;
    }
    
    .show-on-large-screens,
    .show-large{
        display: none;
    }

    .testimonial-content{
        aspect-ratio: unset;
    }

}

  /* ==  Range == */