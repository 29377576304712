@media only screen and (min-width: 1201px) and (max-width: 1400px){
    .container.container-sm{
        max-width: 800px;
    }
}
@media only screen and (min-width: 992px) and (max-width: 1200px){
    .container,
    .container.container-sm{
        width: 100%;
        max-width: inherit;
    }
    .pet-cloud-login .login-box{
        right: 20px;
    }
    .btn-add {
        bottom: 10px;
    }
    .header-right .btn-custom {
        padding: 8px 5px;
        font-size: 16px;
    }
    /* .card-sidebar{
        position: static;
        margin: 10px 0 30px 0;
        flex-direction: row;
        width: 100%;
    }
    .card-sidebar a{
        margin: 10px;
    } */
    .full-width-section {
        padding: 30px 0;
    }
    .my-pet-detail .text{
        padding: 0 0 0 30px;
    }
    .my-pet-detail figure img {
        width: 122px;
        height: 122px;
    }
    .pet-characteristics{
        width: 120px;
        height: 120px;
    }
    .pet-pic{
        right: 20px;
        transform: translateX(50%);
    }
    .bottom-footer .ant-image {
        position: static;
    }
}
@media only screen and (min-width: 768px) and (max-width: 991px){
    .container,
    .container.container-sm{
        width: 100%;
        max-width: inherit;
    }
    .ant-layout-header.header{
        padding: 10px 0;
    }
    .logo {
        margin: 0 0 8px 0;
        text-align: center;
    }
    .header .ant-menu {
        justify-content: center;
    }
    .header-right {
        text-align: center;
        margin:  20px 0 0 0;
    }
    .pet-cloud-login .login-box{
        position: static;
        margin: 20px 0;
    }    
    .pet-cloud-login .footer-login{
        text-align: center;
    }
    .pet-cloud-login .footer-stripe .social-links {
        float: none;
    }
    .pet-cloud-login .footer-stripe .text-right{
        text-align: unset !important;
        margin-bottom: 10px;
    }
    .btn-add {
        bottom: 10px;
    }
    .banner.default{
        height: 80px;
    }
    .digital-exp {
        width: 50%;
    }
    .exp-img-block {
        width: 30%;
    }
    .digital-exp::after {
        width: 100%;
    }
    .insurance-points .ant-typography{
        margin-top: 40px;
    }
    .header-right .btn-custom {
        padding: 8px 5px;
        font-size: 16px;
    }
    .bottom-footer .ant-image {
        position: static;
    }
    .footer-nav {
        margin: 0;
    }
    /* .card-sidebar{
        position: static;
        margin: 10px 0 30px 0;
        flex-direction: row;
        width: 100%;
    }
    .card-sidebar a{
        margin: 10px;
    } */
    .my-pet-detail .text {
        padding: 0 0 0 20px;
    }
    .pet-selction-card{
        width: 100%;
        text-align: center;
    }
    .my-pets .pet-selction-card .ant-radio-button-wrapper {
        margin: 10px !important;
    }
    .full-width-section {
        padding: 30px 0;
    }
    .vet-address {
        padding: 0;
    }
    .pet-characteristics{
        width: 100px;
        height: 100px;
    }
    .custom-radio .ant-radio-wrapper{
        text-align: left;
    }
    .pet-pic{
        right: 20px;
        transform: translateX(50%);
    }
    .bottom-footer .ant-image {
        position: static;
    }
}
@media only screen and (max-width: 767px){
    .container,
    .container.container-sm{
        width: 100%;
        max-width: inherit;
    }
    .logo{
        text-align: center;
        margin: 0;
    }

    .banner .img-fluid.w-100{
        width: 100% !important;
        max-width: 100% !important;
    }

    .banner-caption{
        padding: 30px 0;
    }
    .banner-caption .tagline{
        font-size: 30px;
        line-height: 25px;
        text-shadow: 0 0 3px #fdfdfd;
    }
    .banner-caption .tagline-sub{
        text-shadow: 0 0 3px #fdfdfd;
    }
    .banner-caption .sub-tagline{
        font-size: 20px;
        text-shadow: 0 0 3px #fdfdfd;
    }
    .banner-caption .tagline br{
        display: none;
    }
    .ant-card.plan-box .plan-tagline{
        height: auto;
        padding-bottom: 10px;
    }
    .ant-card.plan-box .plan-tagline br{
        display: none;
    }
    .ant-card.plan-box .illness-type{
        margin-top: 0 !important;
    }
    .mb-5 {
        margin-bottom: 1rem !important;
    }
    .author-section .banner-caption,
    .insurance-plans,
    .pet-insurance,
    .insurance-points{
        padding: 30px 0;
    }
    .form-pet-search.ant-form-inline .ant-form-item.btn-search .ant-btn,
    .form-pet-search.ant-form-inline .ant-input-lg {
        font-size: 12px;
    }
    .pet-cloud-login .login-box{
        position: static;
        margin: 20px 0;
    }    
    .pet-cloud-login .footer-login{
        text-align: center;
        position: static;
        margin-top: 50px;
    }
    .pet-cloud-login .footer-stripe .social-links {
        float: none;
    }
    .pet-cloud-login .footer-stripe .text-right{
        text-align: unset !important;
        margin-bottom: 10px;
    }
    .dashboard-header .col-right ul li {
        margin: 0 0 0 10px;
    }
    .dashboard.home .sidebar-content{
        overflow: hidden;
    }
    .date-modify{
        width: 200px;
    }
    .btn-menu {
        position: absolute;
        top: 40px;
        left: 15px;
        transform: translateY(-55%);
        width: 30px;
        height: 30px;
        color: #01749C;
        background-color: #fff;
        border-radius: 5px;
        text-align: center;
        line-height: 24px;
        cursor: pointer;
    }
    .side-menu .logo{
        text-align: left;
    }
    .side-menu .logo img{
        width: 66px;
    }
    .show-large{
        display: none;
    }
    .ant-card.plan-box .ant-image{
        width: 130px;
        top: -20px;
        right: 0;
    }
    .pet-card .box-inner{
        margin: 4px 0;
    }
    .pet-info-inner .ant-btn {
        right: -3px;
        top: -14px;
    }
    .btn-add {
        bottom: 10px;
    }
    .banner.default{
        height: 82px;
    }
    .banner-pets-cloud{
        padding: 80px 0 0 0;
    }
    .artboard-isometric{
        padding: 50px 0 10px 0;
    }
    .cloud-explore-features{
        padding: 50px 0;
    }
    .ant-layout.dashboard .ant-layout-sider.dashboad-nav,
    .ant-layout.dashboard .ant-layout-sider{
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9999;
        height: 100%;
    }
    .digital-exp::after {
        display: none;
    }
    .digital-exp {
        margin: 33px auto;
        width: 100%;
        z-index: 9;
    }
    .insurance-points .ant-typography{
        margin-top: 40px;
    }
    .ant-typography.section-title{
        font-size: 30px;
        line-height: normal;
        margin: 20px 0 !important;
    }
    .header-right .btn-custom {
        margin: 5px 0;
        padding: 8px 5px;
        font-size: 16px;
    }
    .bottom-footer .ant-image {
        position: static;
    }
    .footer-nav {
        margin: 0;
    }
    .card-sidebar{
        width: 100%;
        position: static;
        margin: 0 0 30px 0;
        padding: 0 15px;
        flex-direction: row;
    }
    .card-sidebar a{
        margin: 10px;
    }
    .pet-selction-card .ant-radio-button-wrapper{
        min-width: auto;
        width: 100%;
        margin: 0 0 10px 0 !important;
    }
    .ant-radio-group.pet-selction-card{
        width: 100%;
    }
    .dashboard-header .menu-dashboard {
        padding: 0;
        margin: 20px 0 10px 0;
        flex-direction: column;
    }
    .dashboard-header .menu-dashboard a{
        margin: 0 0 10px 0;
    }
    .dashboard-header .menu-dashboard a.active::after {
        left: auto;
        top: 50%;
        right: 0;
        margin: 0;
    }
    .dashboard-footer .social-links {
        width: 100%;
        margin-top: 10px;
        text-align: center;
    }
    .footer-links{
        text-align: center;
    }
    .social-links ul{
        display: inline-block;
        margin: 0;
    }
    .personal-info .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list{
        flex-direction: column;
    }
    .personal-info .ant-tabs-nav .ant-tabs-tab{
        flex: initial;
        max-width: initial;
        margin: 10px 0;
    }
    .my-pet-detail{
        margin-bottom: 20px;
    }
    .my-pet-detail .text {
        padding: 0 0 0 10px;
    }
    .my-pet-detail figure img {
        width: 100px;
        height: 100px;
    }
    .pet-characteristics{
        width: 120px;
        height: 120px;
    }
    .my-pet-detail .text .ant-typography.mt-4{
        margin-top: 0 !important;
    }
    .my-pets .pet-selction-card .ant-radio-button-wrapper{
        margin: 5px 0 !important;
    }
    .pet-cloud-login{
        height: auto;
        padding: 20px 0;
    }
    .login-inner{
        position: inherit;
        padding: 0;
    }
    .claim-wizard .steps-content::after {
        display: none;
    }
    .claim-wizard .pet-selction-card .ant-radio-button-wrapper {
        width: auto;
        margin: 10px 0 !important;
    }
    .pet-pic{
        right: 20px;
        transform: translateX(50%);
    }
    .step-inner{
        width: 90px;
    }
    .footer-nav .quick-links li{
        width: 100%;
        margin: 10px 0;
    }
    .pet-needs-table-outer{
        padding: 20px 0;
    }
    .full-width-section{
        padding: 20px 15px;
    }
    .ant-divider.ant-divider-vertical.custom-divider-vertical {
        border-left: none;
        border-bottom: 1px solid #3A3C3E;
        min-height: inherit;
        width: 100%;
        margin: 30px 0;
    }
    .vet-address {
        padding: 0;
    }
    .vets-page-content .section-gray{
        padding: 20px 15px !important;
    }
    .author-section .qoute-block,
    .author-section .banner-caption{
        position: relative;
        float: left;
        width: 100%;
    }
    .contact-text-block{
        margin: 0;
    }
    .coming-soon{
        transform: rotate(0);
        margin: 30px 0;
    }
    .middle .ant-layout-content{
        padding-left: 0;
    }
    .ant-picker.timepicker {
        width: 100%;
    }
    .features-table .top-headings,
    .features-table .ant-collapse{
        width: 800px;
    }
}
@media only screen and (min-width: 320px) and (max-width: 576px){
    .dasboard-modules ul li{
        margin: 5px;
    }
    .claims-page .ant-radio-group .ant-radio-button-wrapper {
        width: 80px;
    }
    .card-sidebar {
        flex-direction: column;
    }
    .card-sidebar a{
        width: 100%;
    }
    .home-cards{
        padding: 15px;
        flex-direction: column;
    }
    .home-cards .card-outer {
        margin: 10px 0;
        width: 100%;
    }
}
@media only screen and (max-width: 470px) {
  
}
@media screen and (max-width: 450px){
  
}
@media screen and (max-width: 320px){
  
}